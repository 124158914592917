import { number } from "yup";
import { IPartyItem, IPartyItemValues } from "../../@types/party";

export const partiesInitialValues = (data: IPartyItem | undefined) => {
    const fields: Partial<IPartyItemValues> = {
        name: "",
        number: "",
        partyRole: 1,
        aliases: []
    }
    if (data) {
        fields.name = data.name
        fields.number = data.number
        fields.partyRole = data.partyRole.id
        fields.aliases = data.aliases
    }

    return fields
}
export const partyColumns = (translate: any) => {
    const headers = [
        {
            text: translate('user.Name', 'Name'),
            sortable: false,
        },
        {
            text: translate('user.id', 'Id'),
            sortable: false,
        },
        {
            text: '',
            sortable: false,
        },
    ];
    return headers
}
export const aliasesPartyColumns = (translate: any) => {
    const headers = [
        {
            text: translate('user.Name', 'Name'),
            sortable: false,
        },
        {
            text: '',
            sortable: false,
        },
    ];
    return headers
}

export const orgTypeOptions = (translate: any) => ([
    {
        label: translate("editParties.ownOrganization", "Own organization"),
        value: 2
    }, {
        label: translate("editParties.counterParty", "Counter party"),
        value: 1
    },

])